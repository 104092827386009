import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutPage = function () {
  return (
    <Layout>
      <Seo
        title="About"
        description="Here at Southern Cross we have built our brand on 3 things: Quality, Integrity, Passion."
      />
      <div>
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 ">
          <div className="mx-auto text-base max-w-prose lg:hidden  ">
            <div>
              <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
                Luxury facility, country prices
              </h2>
              <h3 className="heading-serif">
                Southern Ontario’s only ‘Luxury Included’ Equestrian facility
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none ">
                <StaticImage
                  src="../images/about-us.png"
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Entrance to Southern Cross Showjumpers"
                  loading="eager"
                  className="rounded-lg shadow-lg object-cover object-center"
                />
              </div>
            </div>
            <div className="mt-8 lg:mt-0 ">
              <div className="md:max-w-2xl pl-2 lg:p-0 mx-auto hidden lg:grid">
                <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
                  Quality – Integrity - Passion
                </h2>
                <h3 className="heading-serif">
                  Here at Southern Cross we have built our brand on 3 things:
                </h3>
              </div>
              <div className="mt-5 prose prose-md prose-indigo text-gray-500 mx-auto  md:max-w-xl lg:row-start-1 lg:col-start-1">
                <ul>
                  <li>
                    Quality means delivering the very best in all the care and
                    training we do.
                  </li>
                  <li>
                    Integrity means we are honest, open, real. People trust us
                    to adhere to our word.
                  </li>
                  <li>
                    Passion means we use our drive and commitment to this sport
                    to energize, engage and inspire our riders.
                  </li>
                </ul>
                <p>
                  We strive daily to create an environment of warmth and
                  belonging, where everyone feels welcome.
                </p>
                <p>
                  Passion for the equestrian sport is the foundation for all we
                  do, we believe no dream is too big.
                </p>
                <p>
                  The beauty of the facility is matched by the quality of care
                  for your horse.
                </p>
                <p>
                  We have a place for all levels of riders here. Whether you are
                  a beginner, schooling level hunter/jumper, Trillium, all the
                  way up to ‘A’ Circuit training.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
